@import 'GlobalStyles.scss';

.nav {
    display: flex;
    position: fixed;
    background-color: $black;
    top: 0;
    height: 50px;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    z-index: 1;
    padding-right: 10px;
    @media (min-width: 450px) {
        padding-right: 10px;
    }
    @media (min-width: 700px) {
        padding-right: 30px;
        height: 70px;
    }
    @media (min-width: 1200px) {
        padding-right: 50px;
    }
}
.navLinks {
    top: 0x;
    font-family: $primaryFont;
    font-size: 14px;
    color: $white;
    font-weight: 300;
    letter-spacing: 2px;
    position: relative;
    text-decoration: none;
    line-height: 50px;
    z-index: 1;
    padding: 0px 10px;
    justify-self: flex-end;
    &:focus {
        background-color: $yellow;
        color: $black;
    }
    &:hover {
        color: $purple;
        text-decoration: none;
        font-weight: 500;
    }
    @media (min-width: 500px) {
        font-size: 18px !important;
        padding: 0px 15px;
    }
    @media (min-width: 700px) {
        padding: 0px 30px;
        line-height: 70px;

    }
    @media (min-width: 1200px) {
        padding: 0px 50px;
    }
}
.navbarLogo {
    height: 30px;
    width: auto;
    position: absolute;
    left: 10px;
    top: 9px;
    @media (min-width: 400px) {
        height: 50px;
        left: 20px;
        top: 0px;
    }
    @media (min-width: 700px) {
        height: 72px;
        left: 50px;
    }
}
.footer {
    display: flex;
    position: fixed;
    background-color: $black;
    bottom: 0;
    height: 50px;
    width: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    z-index: 1;
    padding-right: 5px;
    @media (min-width: 450px) {
        padding-right: 10px;
    }
    @media (min-width: 700px) {
        padding-right: 30px;
    }
    @media (min-width: 1200px) {
        padding-right: 50px;
    }
}
.footerLinks {
    top: 0;
    font-family: $primaryFont;
    font-size: 14px;
    color: $white ;
    font-weight: 300;
    letter-spacing: 2px;
    position: relative;
    text-decoration: none !important;
    line-height: 40px;
    z-index: 1;
    padding: 0px 5px;
    display: flex;
    justify-self: space-around;
    &:hover {
        color: $yellow;
        text-decoration: none;
        font-weight: 500;
    }
    @media (min-width: 400px) {
        padding: 0px 15px;
    }
    @media (min-width: 500px) {
        font-size: 18px !important;
        padding: 0px 25px;
    }
    @media (min-width: 700px) {
        padding: 0px 30px;
    }
    @media (min-width: 1000px) {
        padding: 0px 50px;
    }
    a {
        color: $white;
        text-decoration: none;  
        letter-spacing: 2px;
        &:hover {
            color: $yellow;
        }
    }
}
.footerLinks2 {
    top: 0;
    font-family: $primaryFont;
    font-size: 10px;
    color: #757575;
    font-weight: 300;
    letter-spacing: 2px;
    position: relative;
    text-decoration: none !important;
    line-height: 40px;
    z-index: 1;
    margin-right: auto;
    padding: 0px 5px;
    display: relative;
    text-align: left;
    justify-content: start;
    &:hover {
        color: $purple;
    }
    @media (min-width: 400px) {
        padding: 0px 15px;
    }
    @media (min-width: 500px) {
        font-size: 12px !important;
        padding: 0px 25px;
    }
    @media (min-width: 700px) {
        padding: 0px 30px;
    }
    @media (min-width: 1000px) {
        padding: 0px 50px;
    }
    a {
        text-decoration: none;  
        letter-spacing: 2px;
    }
}
.login {
    position: fixed;
    right: 15px;
    bottom: 5px;
    font-family: $primaryFont;
    color: $grey;
    font-size: 20px;
    z-index: 2;
}
.legalCont {
    margin: 0;
}
.footerLegal {
    position: fixed;
    bottom: .5px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: auto;
    font-family: $primaryFont;
    font-size: 7.5px;
    color: $ltgrey !important;
    font-weight: 300;
    letter-spacing: 0.8px;
    text-align: center;
    text-decoration: none !important;
    z-index: 1;
    @media (min-width: 400px) {
        font-size: 8px;
    }
    @media (min-width: 700px) {
        width: 700px;
    }
    @media (min-width: 900px) {
        width: 850px;
        font-size: 9px;
    }
    &:hover {
        color: $yellow;
    }
    a {
        color: $white;
        text-decoration: none;  
    }
}
// .legal1 {
//     position: absolute;
//     font-family: $primaryFont;
//     font-size: 6px;
//     color: $grey !important;
//     bottom: 2px;
//     left: 0;
//     right: 0;
//     margin: auto;
//     max-width: 700px;
//     @media (min-width: 700px) {
//         font-size: 8px;
//     }
// }
.homeContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: space-around;
    margin: 0 auto 60px;
    align-items: center;
    align-content: center;
    text-align: center;
    // gap: 50px;
    
    @media (min-width: 700px) {
        // width: 690px;
        flex-direction: row;
    }
    // @media (min-width: 1000px) {
    // }
}
// .homeContainer1 {
//     display: flex;
//     flex-direction: column;
//     width: 300px;
//     justify-content: space-around;
//     margin: 0 auto 100px;
//     align-items: center;
//     align-content: center;
//     text-align: center;
//     gap: 50px;
//     @media (min-width: 700px) {
//         flex-direction: row;
//     }
// }
.main {
    display: relative;
    text-align: center;
    margin-top: -10px;
}
.homeCards {
    margin: 25px auto ;
    height: 150px;
    width: 200px;
    display: relative;
    text-decoration: none;
    background-image: linear-gradient(to bottom right, $black, $grey, $black);
    border: 5px $yellow solid;
    box-shadow: 3px 3px 7px $ltgrey;
}
.homeLogo {
    width: 101px;
    height: auto;
    margin: 20px auto 10px;
}
.homeImage {
    min-width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 40px auto 0px;
    border-bottom: 5px $black solid;
    height: 150px;
    position: relative;
    background-size: 100%;
    background-image: url('../Images/sc-s6-hero.jpg');
    background-color: $black;
    background-position: center top;
    background-repeat: no-repeat;
    @media (min-width: 500px) {
        height: 250px;
        background-size: cover;
    }
    @media (min-width: 700px) {
        height: 350px;
    }
    @media (min-width: 700px) and (min-height: 600px) {
        height: 300px !important;
    }
    @media (min-width: 900px) {
        height: 400px !important;
        background-image: url('../Images/sc-s6-hero2.jpg');
    }
    @media (min-width: 900px) and (min-height: 600px) {
        height: 350px !important;
    }
    @media (min-width: 1100px) {
        height: 450px;
        border-left: 20px $black solid;
        border-right: 20px $black solid;
        box-sizing: border-box;
    }
    @media (min-width: 1100px) and (min-height: 700px) {
        height: 370px !important;
    }
    @media (min-width: 1400px) {
        height: 450px !important;
        border-left: 100px $black solid;
        border-right: 100px $black solid;
        box-sizing: border-box;
    }
    @media (min-width: 1400px) and (min-height: 700px) {
        height: 370px !important;
    }
}
.homeText {
    font-family: $primaryFont;
    font-size: 24px;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    color: $white;
    // margin: 5px auto;
}
.homeText2 {
    font-family: $primaryFont;
    font-size: 24px;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    color: $white;
    padding: 20px 10px;
    margin: 20px auto;
}
.profileContainer {
    margin: 10px auto 10px;
    cursor: pointer;

    @media (min-width: 700px) {
        margin: 10px 40px;
    }
}
.quizHomeContainer {
    margin: 40px auto 20px;
    position: relative;
    cursor: pointer;

    @media (min-width: 900px) {
        margin: 40px 40px 20px;
    }
}
.readThe {
    font-family: $funfont;
    font-size: 24px;
    letter-spacing: -4px;
}
.playThe {
    width: 90%;
    position: absolute;
    z-index: 5;
    left: -3px;
    right: 0;
    margin: auto;
        
    @media (min-width: 700px) {
       width: 100%;
    }
}
.quizSign {
    width: auto;
    height: 200px;
    margin: 33px 40px 20px;
    position: relative;
    // &:hover {
    //     -webkit-animation: whoopsie 1500ms 150ms linear both;
    //         animation: whoopsie 1500ms 150ms linear both;
    //     -webkit-transform-origin: top left;
    //         transform-origin: top left;
    //     }
    @media (min-width: 700px) {
        height: 225px;
        margin: 33px auto;
    }
    @media (min-width: 900px) {
        height: 250px;
        margin: 35px auto ;
    }
    @media (min-width: 1200px) {
        height: 275px;
        // margin: 33px auto;
    }
}
.quizAnimation {
    -webkit-animation: whoopsie 4.5s linear both;
        animation: whoopsie 4.5s linear both;
    -webkit-transform-origin: top left;
        transform-origin: top left;
}
@keyframes whoopsie {
    0% {
        -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        -webkit-transform: translateY(0);
          transform: translateY(0);
    }
    12% {
        -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          z-index: 2;
    }
    15% {
        -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
          z-index: 2;
    }
    30% {
        -webkit-transform: rotate(5.5deg);
          transform: rotate(5.5deg);
          z-index: 2;
    }
    40% {
        -webkit-transform: rotate(50deg);
          transform: rotate(50deg);
          z-index: 2;
    }
    50% {
        -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
          z-index: 2;
    }
    55% {
        -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
          z-index: 2;
    }
    65% {
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      z-index: 2;
    }
    90% {
      -webkit-transform: rotate(33deg);
            transform: rotate(33deg);
            z-index: 2;
    }
    100%{
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);        
            z-index: 2;
    }
}
.signContainer {
    position: relative;
}
.charSign {
    width: auto;
    height: 100px;
    margin: -5px 40px 40px;
    position: relative;
    @media (min-width: 700px) {
        margin: -8px auto 0px;
    }
    @media (min-width: 900px) {
        height: 125px;
    }
    @media (min-width: 1200px) {
        height: 175px;
    }
    // &:hover {
    //     -webkit-animation: swing-out-bottom-fwd 2s 50ms cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite alternate both;
    //     animation: swing-out-bottom-fwd 2s 50ms cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite alternate both;
    // }
}
.charAnimation {
    -webkit-animation: swing-out-bottom-fwd 2.5s cubic-bezier(0.600, -0.280, 0.735, 0.045)  both;
    animation: swing-out-bottom-fwd 2.5s cubic-bezier(0.600, -0.280, 0.735, 0.045)  both;
}
 @-webkit-keyframes swing-out-bottom-fwd {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
    80% {
    -webkit-transform: rotateX(-88deg);
            transform: rotateX(-88deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0.9;
    }
    100% {
      -webkit-transform: rotateX(-88deg);
              transform: rotateX(-88deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0.5;
    }
  }
  @keyframes swing-out-bottom-fwd {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 1;
    }
    80% {
        -webkit-transform: rotateX(-88deg);
                transform: rotateX(-88deg);
        -webkit-transform-origin: bottom;
                transform-origin: bottom;
        opacity: 0.9;
    }
    100% {
      -webkit-transform: rotateX(-88deg);
              transform: rotateX(-88deg);
      -webkit-transform-origin: bottom;
              transform-origin: bottom;
      opacity: 0.5;
    }
  }
  .legalContainer {
      margin: 100px auto;
      padding: 20px 50px;
      @media (min-width: 700px) {
        padding: 30px 100px;
    }

      h1 {
          font-family: $primaryFont;
          color: $black;
          border-bottom: 3px solid $yellow;
          font-weight: 300;
          line-height: 1.4;
          text-align: center;
      }
      p {
        font-family: $primaryFont;
        color: $grey;
        line-height: 1.4;
        font-size: 15px;
        margin: 20px auto;
    }
    li {
        font-family: $primaryFont;
        color: $grey;
        line-height: 1.4;
        font-size: 15px;
        margin: 10px auto;
    }
  }
// .skew1Container {
//     display: flex;
//     position: relative;
//     width: 211px;
//     height: 161px;
//     margin: 25px auto;
// }
// .skew2Container {
//     display: flex;
//     position: relative;
//     width: 211px;
//     height: 161px;
//     margin: 25px auto;
// // }
// .skew1 {
//     width: 210px;
//     height: 100px;
//     // background-color: $ltyellow;
//     background-image: linear-gradient(-45deg, $yellow, $ltyellow, $yellow);

//     opacity: 0;
//     position: absolute;
//     top: 55px;
//     left: 0px;
//     right: 0px;

//     &:hover {
//     // display: ;
//     opacity: .99;
//     box-sizing: border-box;
//     border-top: 5px $yellow solid;
//     border-bottom: 5px $yellow solid;
//     -ms-transform: skewY(8deg);
//     -webkit-transform: skewY(8deg);
//     transform: skewY(8deg);
//     }
// }
// .unskew1 {
//     font-family: $primaryFont;
//     font-weight: 500;
//     // font-style: italic;
//     font-size: 20px;
//     width: 210px;
//     height: 100px;
//     opacity: 0;
//     // color: red;
//     position: absolute;
//     top: 15px;
//     left: 0px;
//     right: 0px;
//     margin: 0;
//     padding: 0;

//     &:hover {
//     color: $grey;
//     opacity: 100;
//     position: absolute;
//     // display: inline;
//     top: 15px;
//     left: 0px;
//     right: 0px;
//     -ms-transform: skewY(-8deg);
//     -webkit-transform: skewY(-8deg);
//     transform: skewY(-8deg);
//     }
// }
