@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');

$primaryFont: 'Roboto', sans-serif;
$cursiveFont: 'Alex Brush', cursive;

@font-face {
    font-family: 'Frontage-Regular';
    src: local('Frontage-Regular'), url(../Images/Frontage-Regular.ttf) format('truetype')
}
$funfont: 'Frontage-Regular';

$yellow: #F1C713;
$ltyellow: rgb(250, 226, 120);
$lteryellow: rgb(252, 243, 204);
$dkyellow: rgb(194, 160, 10);
$dkyellow: rgb(194, 160, 10);
$black: black;
$white: whitesmoke;
$medgrey: #4e4e4e;
$grey: #444444;
$ltgrey: #9e9e9e;
$purple: purple;
$ltpurple: rgb(172, 0, 172);
$dkpurple: rgb(92, 1, 92);

$red: rgb(214, 1, 1);
$ltred: rgb(253, 48, 48);
$dkred: rgb(165, 1, 1);

$smshadow: 1px 1px 3px #c1c1c1;
$medshadow: 3px 3px 7px #c1c1c1;
$lgshadow: 3px 3px 10px $grey;
$innershadow: inset 0px 0px 7px #c1c1c1;

* {
    margin: 0;
    padding: 0;
}
body {
    background-color: $white;
}
html {
    overflow-y: scroll;
    width: 100%;
}