@import 'GlobalStyles.scss';

.quizLogo {
    width: 250px;
    height: auto;
    margin: 10px auto 0px;
    @media (min-width: 400px) {
        width: 300px;
    }
    @media (min-width: 550px) {
        width: 350px;
    }
    @media (min-width: 700px) {
        width: 400px;
    }
}
.playButton {
    margin: 30px auto 10px;
    padding: 10px 22px 10px 10px;
    background-image: linear-gradient(to bottom left, $purple, $ltpurple, $dkpurple);
    font-family: $funfont;
    font-size: 28px;
    width: 150px;
    height: 150px;
    color: $white;
    line-height: 1.1;
    letter-spacing: -6px;
    position: relative;
    border-radius: 50%;
    border: none;
    @media (min-width: 400px) {
        margin: 60px auto 10px ;
    }
    &:focus {
        outline: none;
    }
    &:active {
        background-color: $purple;
        -webkit-box-shadow: $innershadow;
        -moz-box-shadow: $innershadow;
             box-shadow: $innershadow;
             outline: none;

    }
    &:before {
        border-radius: 100%;
        background-image: linear-gradient(to bottom right, $purple, $ltpurple, $dkpurple);
        padding: 5px;
        z-index:-1;
        top: -5px;
        left: -5px;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border: none;
        -webkit-box-shadow: $lgshadow;
        -moz-box-shadow: $lgshadow;
            box-shadow: $lgshadow;
    }
    .text {
        text-align: center;
        margin: 30px 20px 10px 10px;
    }
}
.readyPlay {
    h1 {
        font-family: $funfont;
        font-size: 26px;
        color: $purple;
        margin: 30px auto 0px;
        letter-spacing: -5.5px;
        @media (min-width: 350px) {
            font-size: 30px;
            margin: 40px auto 10px;
        }
        @media (min-width: 500px) {
            margin: 55px auto 10px;
        }
        @media (min-width: 700px) {
            font-size: 40px;
            letter-spacing: -8px;

        }
    }
}
.hidden {
    display: none
}
.quizContainer {
    margin: 60px auto 70px;
    text-align: center;
    // padding: 0px 20px;
    position: relative;
    width: 275px;
    @media (min-width: 400px) {
        margin: 70px auto 70px;
        // padding: 0px 30px;
        width: 300px;
    }
    @media (min-width: 500px) {
        width: 400px;
    }
    @media (min-width: 700px) {
        margin: 80px auto 70px;
        width: 600px;
    }
    @media (min-width: 1000px) {
        margin: 80px auto 70px;
        width: 800px;
    }
}
.quizWelcome {
    font-family: $funfont;
    font-weight: 400;
    font-size: 30px;
    width: auto;
    text-align: center;
    margin: 5px auto;
    letter-spacing: -5px;
    @media (min-width: 700px) {
        font-size: 40px;
        margin: 25px auto 5px;
        letter-spacing: -8px;
    }
}
.chooseSeasons {
        font-family: $primaryFont;
        color: $grey;
        font-size: 16px;
        font-weight: 300;
        margin: 20px auto 5px;
        text-transform: uppercase;
        width: auto;
        @media (min-width: 500px) {
            font-size: 18px;
            width: 350px;
        }
        @media (min-width: 700px) {
            font-size: 20px;
            width: 380px;
            margin-top: 10px;
        }
}
.seasonButtonDivIntro {
    width: auto;
    margin: auto;
    padding: 0;
    @media (min-width: 700px) {
        width: 460px;
    }
}
.seasonButtonDivGame {
    width: 250px;
    margin: 0 auto;
    padding: 0px 0px 8px;
    box-sizing: border-box;
    outline: none;

    @media (min-width: 400px) {
        width: 300px;
    }
}
.seasonButtonDivGameActive {
    width: 250px;
    margin: 0 auto;
    background-color: white;
    padding: 0px 0px 8px;
    box-sizing: border-box;
    -webkit-box-shadow: $innershadow;
    -moz-box-shadow: $innershadow;
        box-shadow: $innershadow;
    outline: none;

    @media (min-width: 400px) {
        width: 300px;
    }
}
.seasonNotSelected {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: $ltyellow;
    font-family: $funfont;
    font-size: 10px; 
    width: 115px;
    letter-spacing: -2px;
    color: $medgrey;
    border: none;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.seasonSelected {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 10px;
    width: 115px;
    letter-spacing: -2px;
    color: $black;
    border: none;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.seasonNotSelectedAll {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: $ltyellow;
    font-family: $funfont;
    font-size: 10px; 
    width: 115px;
    letter-spacing: -2px;
    color: $medgrey;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;
        
    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.seasonSelectedAll {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 10px;
    width: 115px;
    letter-spacing: -2px;
    color: $black;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.gameAllClearDiv {
    height: auto;
    margin: -10px 0px 0px;
}
.seasonNotSelectedAllGame {
    margin: 3px 4px;
    padding: 10px 10px 5px;
    background-color: $ltyellow;
    font-family: $funfont;
    font-size: 10px; 
    width: 100px;
    letter-spacing: -2px;
    color: $medgrey;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;
        
    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.seasonSelectedAllGame {
    margin: 3px 4px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 10px;
    width: 100px;
    letter-spacing: -2px;
    color: $black;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.disabled {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: #c1c1c1;
    font-family: $funfont;
    font-size: 10px; 
    width: 115px;
    letter-spacing: -2px;
    color: $medgrey;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;
    @media (min-width: 700px) {
        font-size: 12px; 
        width: 135px;
    }
    &:focus {
        outline: none;
    }
}
.disabledGame {
    margin: 3px 2px;
    padding: 10px 10px 5px;
    background-color: #c1c1c1;
    font-family: $funfont;
    font-size: 10px; 
    width: auto;
    min-width: 32px;    
    letter-spacing: -2px;
    color: $grey;
    border: none;
    display: inline-block;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;
    @media (min-width: 700px) {
        font-size: 12px; 
    }
    &:focus {
        outline: none;
    }
}
.updateSeasons {
    padding: 10px 10px 5px;
    background-color: $purple;
    color: $white;
    font-family: $funfont;
    font-size: 10px; 
    display: block;
    margin: 7px auto 0;
    letter-spacing: -2px;

    @media (min-width: 700px) {
        font-size: 12px; 
    }
    &:focus {
        outline: none;
    }
}
.changeSeasons {
    font-family: $funfont;
    color: $black;
    background-color: $yellow;
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin: 0 auto;
    position: relative;
    text-transform: uppercase;
    width: 250px;
    height: 25px;
    line-height: 8px;
    text-align: center;
    padding: 5px 0px 4px;
    letter-spacing: -3px;
    user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    box-sizing: border-box;
    @media (min-width: 400px) {
        width: 300px;
    }
    @media (min-width: 700px) {
        font-size: 13px !important;
    }
}
.toggleSeasonMenu {
    font-size: 32px !important;
    // margin-left: 15px;
    top: 5px;
    text-align: right;
    // display: inline;
    position: relative;
    @media (min-width: 500px) {
        font-size: 36px !important;
    }
    @media (min-width: 700px) {
        font-size: 40px !important;
    }
}
.seasonNotSelectedGame {
    margin: 0px 2px;
    padding: 10px 10px 5px;
    background-color: $ltyellow;
    font-family: $funfont;
    font-size: 10px; 
    width: auto;
    min-width: 32px;
    letter-spacing: -2px;
    display: inline-block;
    color: $medgrey;
    border: none;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
    }
    &:focus {
        outline: none;
    }
}
.seasonSelectedGame {
    margin: 0px 3px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 10px;
    display: inline-block;
    color: $black;
    border: none;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 700px) {
        font-size: 12px; 
    }
    &:focus {
        outline: none;
    }
}

.quoteContainer {
    font-family: $primaryFont;
    font-weight: 200;
    font-size: 40px;
    margin: 20px 5px 10px;
    display: block;
    position: relative;

    @media (min-width: 500px) {
        margin: 10px 5px 10px;
    }
}
.quoteFormContainer {
    margin: 25px auto 75px;
}
.quizWho {
    font-family: $funfont;
    font-weight: 300;
    font-size: 18px;
    width: 225px;
    color: $purple;
    letter-spacing: -4.25px;
    text-align: left;
    margin: 20px auto 0px;
    @media (min-width: 400px) {
        width: 275px;
    }
    @media (min-width: 500px) {
        font-size: 22px;
        margin: 25px auto 0px;
        letter-spacing: -5.5px;
        width: 375px;
    }
    @media (min-width: 700px) {
        width: 575px;
    }
    @media (min-width: 1000px) {
        width: 675px;
    }
    @media (min-width: 1300px) {
        margin-top: 50px;
    }
}
.randomQuote {
    font-family: $primaryFont;
    font-weight: 300;
    font-size: 16px;
    font-style: italic;
    display: block;
    width: 200px;
    color: $grey;
    text-align: center;
    margin: 5px auto 5px;
    padding: 5px 10px;
    position: relative;  
    height: auto;
    @media (min-width: 400px) {
        font-size: 18px;
        padding: 5px 10px;
        width: 250px;
    }
    @media (min-width: 500px) {
        font-size: 20px;
        width: 350px;
    }
    @media (min-width: 700px) {
        // font-size: 24px;
        padding: 10px 15px;
        width: 550px;
    }
    @media (min-width: 1000px) {
        width: 650px;
    }
}
.quoteBrackets {
    display:inline-block;
    width: 225px;
    text-align: center;
    margin: 2px auto;
    background-color: #dedede;
    padding: 5px 10px;
    border: $grey 5px solid;
    position:relative;
    height: auto;
    &:before {
        background-color: #dedede;
        width: 185px;
        bottom: -5px;
        content: "";
        left: 15px;
        position: absolute;
        right: 15px;
        top: -5px;
    }
    > * {
        position: relative;
        z-index: 1;
    }
    @media (min-width: 400px) {
        width: 275px;
        &:before {
            width: 235px;
        }
    }
    @media (min-width: 500px) {
        width: 375px;
        &:before {
            width: 335px;
        }
    }
    @media (min-width: 700px) {
        width: 575px;
        &:before {
            width: 535px;
        }
    }
    @media (min-width: 1000px) {
        width: 675px;
        &:before {
            width: 635px;
        }
    }
}
.answerContainer {
    margin: 25px auto 25px;
    width: 245px;
    @media (min-width: 500px) {
        width: 400px;
    }
    @media (min-width: 700px) {
        width: 600px;
    }
}
.answer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 200px;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    font-family: $funfont;
    font-size: 14px;
    color: $white;
    background: $purple;
    letter-spacing: -3.5px;
    z-index: 3;
    @media (min-width: 500px) {
        width: 300px;
        font-size: 16px;
    }
    @media (min-width: 700px) {
        font-size: 20px;
        letter-spacing: -4px;
    }
}
.answerDiv {
    position: relative;
    width: 200px;
    margin: 0px auto 50px;

    @media (min-width: 500px) {
        width: 300px;
    }
}
.answerButton {
    margin: 5px 5px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 11px;
    border: none;
    letter-spacing: -1.5px;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 500px) {
        font-size: 14px;
    }
    &:focus {
        background-color: $purple;
        color: $white;
        outline: none;
    }
}
.quizCharPhoto {
    width: 200px;
    display: block;
    margin: 10px auto 0px;
    background-image: linear-gradient(to bottom right, $grey, $ltgrey, $grey);
    -webkit-box-shadow: $lgshadow;
        -moz-box-shadow: $lgshadow;
            box-shadow: $lgshadow;
    -webkit-animation: swirl 0.6s ease-out both;
            animation: swirl 0.6s ease-out both;
    @media (min-width: 500px) {
        width: 300px;
    }
}
 @-webkit-keyframes swirl {
    0% {
      -webkit-transform: rotate(-540deg) scale(0);
              transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0) scale(1);
              transform: rotate(0) scale(1);
      opacity: 1;
    }
  }
  @keyframes swirl {
    0% {
      -webkit-transform: rotate(-540deg) scale(0);
              transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotate(0) scale(1);
              transform: rotate(0) scale(1);
      opacity: 1;
    }
  }
.nextButton {
    margin: 10px auto 0;
    right: 0;
    padding: 7px 10px 3px;
    background-color: $ltgrey;
    font-family: $funfont;
    font-size: 10px;
    position: absolute;
    border: none;
    @media (min-width: 700px) {
        font-size: 14px;
    }
}
.tryAgain {
    margin: 15px auto 10px;
    display: block;
    font-family: $funfont;
    font-size: 16px;
    width: 200px;
    line-height: 1.3;
    color: $purple;
    -webkit-animation: shake 2s infinite ease-in;
    animation: shake 2s infinite ease-in;
    @media (min-width: 700px) {
        font-size: 24px;
        width: 500px;
    }
}
 @-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
      }
      5%, 15%, 25%, 35% {
        -webkit-transform: translateX(-8px);
                transform: translateX(-8px);
      }
      10%, 20%, 30% {
        -webkit-transform: translateX(8px);
                transform: translateX(8px);
      }
      40% {
        -webkit-transform: translateX(8px);
                transform: translateX(8px);
      }
      45% {
        -webkit-transform: translateX(-8px);
                transform: translateX(-8px);
      }
      50% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
        }
  }
  @keyframes shake {
    0%, 100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    5%, 15%, 25%, 35% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
    10%, 20%, 30% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    40% {
      -webkit-transform: translateX(8px);
              transform: translateX(8px);
    }
    45% {
      -webkit-transform: translateX(-8px);
              transform: translateX(-8px);
    }
    50% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
      }
  }
  .scoreboard {
    color: $white;
    margin-bottom: 50px;
    margin: auto;
    padding-bottom: 5px;
    width: 250px;
    background-color: #dedede;
    border-bottom: 5px $purple solid;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    @media (min-width: 500px) {
        width: 375px;
    }
    @media (min-width: 700px) {
        width: 575px;
    }
    @media (min-width: 1000px) {
        width: 675px;
    }
    @media (min-width: 1300px) {
        margin-top: 50px;
    }
    
    h1 {
        font-family: $funfont;
        font-size: 18px;
        padding: 14px 15px 10px 15px;
        letter-spacing: -3px;
        background: $purple;
        @media (min-width: 700px) {
            font-size: 22px;
        }
    }
    h2 {
        font-family: $primaryFont;
        font-size: 16px;
        color: $black;
        padding: 10px 10px 8px;
        @media (min-width: 700px) {
            font-size: 18px;
        }
    }
    h3 {
        font-family: $primaryFont;
        font-size: 14px;
        color: $black;
        padding: 1px 10px;
        @media (min-width: 500px) {
            display: inline;
        }
        @media (min-width: 700px) {
            font-size: 16px;
        }
    }
    p {
        color: $purple;
        display: inline;
    }
    h5 {
        position: absolute;
        width: 10px;
        height: 15px;
        left: 88%;
        top: 0px;
        font-size: 32px;
        background-color: rgba(245, 245, 245, 0);
        color: $white;
        border: none;
        z-index: 0;
        outline: none;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        @media (min-width: 500px) {
            width: 13px;
            height: 18px;
            left: 90%;
            top: -5px;
            font-size: 40px;
        }
        @media (min-width: 700px) {
            width: 15px;
            height: 20px;
            left: 93%;
            top: -7px;
            font-size: 46px;
        }
    }
    div {
        position: relative;
    }
  }