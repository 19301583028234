@import 'GlobalStyles.scss';

.characterContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 60px auto 50px;
    padding: 25px;
    text-align: auto;
    @media (min-width: 1000px) {
        margin: 60px 100px 50px;
    }
    h3 {
        font-family: $primaryFont;
        margin: 20px auto 10px;
        font-size: 18px;
        display: block;
        @media (min-width: 1000px) {
            margin: 30px auto 15px;
            font-size: 22px;

        }
    }
    p {
        font-family: $primaryFont;
        font-size: 16px;
        font-weight: 100;
        color: $grey;
        line-height: 1.4;
        text-align: left;
        display: block;
        padding: 0px 25px;
        @media (min-width: 1000px) {
            font-size: 20px;
        }
    }
    }
.frame {
  background-color: $ltgrey;
  border: solid 25px $grey;
  border-bottom-color: $grey;
  border-left-color: $grey;
  border-radius: 2px;
  border-right-color: $grey;
  border-top-color: $grey;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
  box-sizing: border-box;
  display: inline-block;
  margin: 30px auto 0px;
  width: auto;
  height: auto;
  position: relative;
  text-align: center;
//   justify-self: center;
  align-content: center;
  &:before {
    border-radius:2px;
    bottom:-2vmin;
    box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
    content:"";
    left:-2vmin;
    position:absolute;
    right:-2vmin;
    top:-2vmin;
  }
  &:after {
    border-radius:2px;
    bottom:-2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    content:"";
    left:-2.5vmin;
    position:absolute;
    right:-2.5vmin;
    top:-2.5vmin;
  }
}
.charPhoto {
    height: auto;
    width: 175px;
    margin: 20px;
    @media (min-width: 350px) {
      width: 200px;
    }
    @media (min-width: 500px) {
      width: 250px;
    }
    @media (min-width: 700px) {
      width: 350px;
    }
    @media (min-width: 900px) {
      width: 500px;
    } 
}
.charQuoteButtonToggle {
  width: 250px;
  margin: 10px auto 15px;
  padding: 10px 15px 5px;
  background-color: $ltyellow;
  font-family: $funfont;
  font-size: 14px;
  letter-spacing: -2.5px;
  border: none;
  display: block;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
  @media (min-width: 700px) {
    margin: 20px auto 25px;
  }
  @media (min-width: 1000px) {
    width: 350px;
    font-size: 16px;
  }
}
.charNew {
margin: auto;
}
.character {
  text-align: center;
}
.charQuotesLabel {
  font-family: $funfont;
  font-size: 16px;
  font-weight: 300;
  color: $purple;
  line-height: 1.4;
  @media (min-width: 1000px) {
      font-size: 22px;
  }
}
.labelHR {
  border: 1px solid $purple;
  margin: 0 auto 15px;
  width: 214px;
  @media (min-width: 1000px) {
    width: 290px;
  }
}
.charQuoteMap {
  width: 300px;
  margin: auto;
  // @media (min-width: 700px) {
  //   width: 500px;
  // }
  // @media (min-width: 1000px) {
  //     width: 600px;
  // }
  @media (min-width: 700px) {
      width: 350px;
  }
  @media (min-width: 1000px) {
      width: 400px;
  }
  @media (min-width: 1200px) {
      width: 450px;
  }
}
.charQuotes {
  font-family: $primaryFont;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: $purple;
  line-height: 1.4;
  margin: 5px auto 5px;
  padding: 0px 15px;
  text-align: center;
  @media (min-width: 700px) {
    font-size: 18px;
  }
  @media (min-width: 1000px) {
      font-size: 20px;
  }
}
.charQuotesSeason {
  font-family: $primaryFont;
  font-size: 14px;
  font-weight: 100;
  color: $grey;
  line-height: 1.4;
  margin-bottom: 2px;
  text-align: center;
  @media (min-width: 1000px) {
      font-size: 18px;
  }
}
.charQuotesEpisode {
  font-family: $primaryFont;
  font-size: 14px;
  font-weight: 100;
  color: $grey;
  line-height: 1.4;
  margin-bottom: 25px;
  text-align: center;
  @media (min-width: 1000px) {
      font-size: 18px;
  }
}