@import 'GlobalStyles.scss';
.admin {
    margin: 100px auto 75px;
}
.loginForm {
    margin: 100px auto;
    position: relative;
    text-align: center;
}
.formlogo {
    width: 300px;
    margin: 20px auto;
}
.loginHeader {
    font-family: $funfont;
    font-size: 20px;
}
.loginInput {
    margin: 5px;
    padding: 2px;
}
.loginButton {
    display: block;
    margin: 5px auto 5px;
    padding: 10px 10px 5px;
    background-color: $yellow;
    font-family: $funfont;
    font-size: 11px;
    border: none;
    letter-spacing: -1.5px;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 500px) {
        font-size: 14px;
    }
    &:focus {
        background-color: $purple;
        color: $white;
        outline: none;
    }
}
.logoutButton {
    // display: block;
    position: absolute;
    right: 25px;
    top: 85px;
    margin: 0px auto 5px;
    padding: 10px 10px 5px;
    background-color: $purple;
    font-family: $funfont;
    color: $white;
    font-size: 11px;
    border: none;
    letter-spacing: -1.5px;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 500px) {
        font-size: 14px;
    }
    &:focus {
        background-color: $purple;
        color: $white;
        outline: none;
    }
}
.charQuoteButtonToggleAdmin {
    width: 250px;
    margin: 5px 30px 5px;
    padding: 10px 15px 5px;
    background-color: $ltyellow;
    font-family: $funfont;
    font-size: 14px;
    letter-spacing: -2.5px;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    @media (min-width: 700px) {
      margin: 10px 35px 10px;
    }
    @media (min-width: 1000px) {
      width: 350px;
      font-size: 16px;
    }
}
.existingQuotes {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    margin: 125px 0 0;
}
.specificChar {
    margin: 5px 15px 5px;
    text-align: center;
    width: 300px;
    @media (min-width: 700px) {
      width: 350px;
      margin: 5px 30px 5px;
    }
    @media (min-width: 1000px) {
        width: 400px;
    }
    @media (min-width: 1200px) {
        width: 450px;
        margin: 5px 50px 5px;
    }
}
.quotesShownAdmin {
    width: 300px;
    border: 2px #c1c1c1 solid;
    background-color: #c1c1c136;
    box-sizing: border-box;
    -webkit-box-shadow: $medshadow;
    -moz-box-shadow: $medshadow;
        box-shadow: $medshadow;
    @media (min-width: 700px) {
        width: 350px;
    }
    @media (min-width: 1000px) {
        width: 400px;
    }
    @media (min-width: 1200px) {
        width: 450px;
    }
}
.addNewForm {
    margin: 20px auto 20px;
    text-align: center;
    width: 300px;
    @media (min-width: 700px) {
        width: 350px;
    }
    @media (min-width: 1000px) {
        width: 400px;
    }
    @media (min-width: 1200px) {
        width: 450px;
    }
    // h1 {
    //     font-family: $primaryFont;
    //     font-size: 20px;
    //     color: $purple;
    // }
}
.addQuoteInputCharacter {
    margin: 5px auto 5px;
    padding: 10px 10px 5px;
    display: block;
    width: 270px;
    position: relative;
    left: -3px;
    outline: none;
    border: none;
    @media (min-width: 700px) {
        width: 320px;
    }
    @media (min-width: 1000px) {
        width: 370px;
    }
    @media (min-width: 1200px) {
        width: 420px;
    }
}
.addQuoteInputQuoteText {
    margin: 5px auto 5px;
    padding: 10px 10px 5px;
    display: block;
    width: 270px;
    position: relative;
    left: -3px;
    outline: none;
    border: none;
    @media (min-width: 700px) {
        width: 320px;
    }
    @media (min-width: 1000px) {
        width: 370px;
    }
    @media (min-width: 1200px) {
        width: 420px;
    }
}
.addQuoteInputSeason {
    margin: 5px 5px 5px 0;
    padding: 10px 10px 5px;
    width: 130px;
    position: relative;
    left: -3px;
    outline: none;
    border: none;
    @media (min-width: 700px) {
        width: 155px;
    }
    @media (min-width: 1000px) {
        width: 180px;
    }
    @media (min-width: 1200px) {
        width: 205px;
    }
}
.addQuoteInputEpisode {
    margin: 5px 0px 5px 5px;
    padding: 10px 10px 5px;
    width: 130px;
    position: relative;
    left: -3px;
    outline: none;
    border: none;
    @media (min-width: 700px) {
        width: 155px;
    }
    @media (min-width: 1000px) {
        width: 180px;
    }
    @media (min-width: 1200px) {
        width: 205px;
    }
}
.addNewButtonSubmit {
    display: block;
    margin: 10px auto 5px;
    padding: 10px 10px 5px;
    background-color: $purple;
    font-family: $funfont;
    color: $white;
    font-size: 11px;
    border: none;
    letter-spacing: -1.5px;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;

    @media (min-width: 500px) {
        font-size: 14px;
    }
    &:focus {
        background-color: $purple;
        color: $white;
        outline: none;
    }
}
.quoteButton {
    position: relative;
    display: inline-block;
    top: -20px;
    margin: 5px auto 0;
    padding: 10px 10px 5px;
    background-color: $purple;
    font-family: $funfont;
    color: $white;
    font-size: 11px;
    border: none;
    letter-spacing: -1.5px;
    -webkit-box-shadow: $smshadow;
    -moz-box-shadow: $smshadow;
        box-shadow: $smshadow;
    &:focus {
        background-color: $purple;
        color: $white;
        outline: none;
    }
}
.showAdminButtons {
    display: inline;
}
.hideAdminButtons {
    display: none;
}
.addNewQuote {
    width: 275px;
    margin: 0px auto 5px;
    padding: 10px 15px 5px;
    background-color: $purple;
    color: $white;
    font-family: $funfont;
    font-size: 14px;
    display: block;
    letter-spacing: -2.5px;
    border: none;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    @media (min-width: 700px) {
      margin: 10px auto 10px;
      width: 320px;
    }
    @media (min-width: 1000px) {
      width: 370px;
      font-size: 16px;
    }
    @media (min-width: 1200px) {
        width: 420px;
    }
    &:focus {
        outline: none;
    }
}
.charQuotesAdmin {
    font-family: $primaryFont;
    font-size: 16px;
    font-weight: 300;
    font-style: italic;
    color: $purple;
    line-height: 1.4;
    margin: 5px auto 5px;
    padding: 0px 15px;
    text-align: center;    
  }
  .charQuotesSeasonAdmin {
    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 100;
    color: $grey;
    line-height: 1.4;
    margin-bottom: 2px;
    text-align: center;
  }
  .charQuotesEpisodeAdmin {
    font-family: $primaryFont;
    font-size: 14px;
    font-weight: 100;
    color: $grey;
    line-height: 1.4;
    margin-bottom: 25px;
    text-align: center;
  }